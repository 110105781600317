import React from "react"
import matchSorter from 'match-sorter'
import ReactTable from 'react-table'
import 'react-table/react-table.css'

const Cell = ({ value }) => (
  <div style={{ 'textAlign': 'center'}}>
    {value}
  </div>
)

const defaultSortMethod = (a, b, desc) => {
  // force null and undefined to the bottom
  a = a === null || a === undefined ? '' : a
  b = b === null || b === undefined ? '' : b
  // force any string values to lowercase
  a = typeof a === 'string' ? a.toLowerCase() : a
  b = typeof b === 'string' ? b.toLowerCase() : b
  // Return either 1 or -1 to indicate a sort priority
  if (parseInt(a) > parseInt(b)) {
    return 1
  }
  if (parseInt(a) < parseInt(b)) {
    return -1
  }
  // returning 0, undefined or any falsey value will use subsequent sorts or
  // the index as a tiebreaker
  return 0
}

const columns = [
  {
    Header: 'Place Overal',
    accessor: 'overal',
    width: 120,
    Cell: Cell
  }, {
    Header: 'Name',
    accessor: 'name',
    filter: 'includes',
    filterMethod: (filter, rows) => matchSorter(
      rows, filter.value, { keys: ['name', 'origins'] }
    ),
    filterAll: true,
    Cell: ({
      original: { name, bib, division, origins }
    }) => (
      <div>
        <div style={{ textTransform: 'capitalize' }}> 
          {name.toLowerCase()}
        </div>
        <div style={{
          'borderTop': '1px solid rgba(115, 115, 115, 0.3)',
          'display': 'inline-block',
        }}> 
          <span style={{ color: '#737373' }}>
            Bib {bib}, {division}, {origins}
          </span>
        </div>
      </div>
    ),
    minWidth: 200
  }, {
    Header: 'Time',
    accessor: 'time',
    Cell: Cell
  }, {
    Header: 'Pace',
    accessor: 'pace',
    Cell: Cell
  }, {
    Header: 'Place gender',
    accessor: 'gender',
    Cell: Cell
  }
];

const Table = ({ results }) => (
	<ReactTable
    filterable
    data={results}
    columns={columns}
    defaultPageSize={50}
    showPageSizeOptions={false}
    defaultSortMethod={defaultSortMethod}
  />
)

export default Table