import React from 'react'
import { Link } from 'gatsby'

const Links = ({ links }) => 
  <div>
    {links.map(({ link, title }) => {
      return <Link
        style={{ display: 'block' }}
        to={link}
        key={link}
      >
        {title}
      </Link>
    })}
  </div>

export default Links;