import React from 'react'

import Layout from '../components/layout'
import Table from '../components/table'
import Links from '../components/links'
import SEO from '../components/seo'

export default ({ pageContext: { title, results, links } }) => (
  <Layout>
    <SEO title={title} />

    <h1>{title}</h1>

    <Links links={links} />

    <Table results={results} />

    <Links links={links} />

  </Layout>
);